:root {
    --colors-yellow: gold;
    --colors-orange: orange;
    --colors-red: orangered;
    --colors-textRed: crimson;
}

.messageContainer {
    /* padding-top: 120px; */
    padding-bottom: 60px;
    width: 1320px;
    max-height: 300px;
    background-color: var(--colors-primary);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.title {
    line-height: 0.3rem;
    margin-top: 200px;
}


@media only screen and (min-width: 992px) {
    .messageContainer {
        min-height: 500px;
    }
}