:root {
  --colors-pink-light: rgba(255, 226, 246, 1);
  --colors-pink-dark: rgba(255, 119, 193, 1);
  --colors-gradient: radial-gradient(
    circle,
    rgb(255, 239, 250) 0%,
    rgb(255, 34, 155) 100%
  );
  --colors-gradient-yellow: linear-gradient(0deg, rgba(251,242,1,1) 0%, rgba(196,0,0,1) 100%);
  --colors-gradient-black: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(12,10,10,0.9) 100%);
}


.buttonWrapper {
  top: -2rem;
  position: relative;
  width: 18rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  z-index: 1;
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}

.hover {
    position: absolute;
    top: -2.7rem;
    left: 1.46rem;
    width: 15rem;
    height: 13rem;
    border-radius: 50%;
    /*background-color: blue;
    */z-index: 6;
    text-align: center;
    display: inline-block;
    transform-style: preserve-3d;
    transform: rotateX(50deg);
    -webkit-transform: rotateX(50deg);
    -moz-transform: rotateX(50deg);
    -ms-transform: rotateX(50deg);
    -o-transform: rotateX(50deg);
}

.hover:hover {
    box-shadow: inset 0px 0px 40px 30px rgb(93, 0, 50);
}

.glass {
  position: absolute;
  top: -0.7rem;
  left: 4rem;
  width: 10rem;
  height: 6rem;
  background-color: rgba(255, 255, 255);
  opacity: 0.4;
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  z-index: 5;
  text-align: center;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}



.top {
  /*position: absolute;
  */padding-top: 0.8rem;
  top: 0;
  left: 0;
  width: 8rem;
  height: 5.5rem;
  background-color: rgb(255, 2, 2);
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  z-index: 4;
  text-align: center;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}



.topBackgroundPink {
  position: absolute;
  top: -1rem;
  left: 3rem;
  /*margin-top: -0.8rem;
  */width: 12rem;
  height: 9rem;
  background: var(--colors-gradient);
  border-radius: 50%;
  z-index: 3;
  text-align: center;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}


.topBackgroundBlack {
  position: absolute;
  top: -2.7rem;
  left: 1.46rem;
  /*margin-top: -3.5rem;
  */width: 15rem;
  height: 13rem;
  background-color: #000;
  border-radius: 50%;
  z-index: 2;
  text-align: center;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}


.text {
  font-size: 4rem;
  margin-top: -0.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
a {
  text-decoration: none;
}

.reverse {
  display: inline-block;
  padding: 0;
  margin-left: -60px;
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
}

.buttonAround {
  position: absolute;
  top: 0;
  left: 1.5rem;
  width: 14.94rem;
  height: 18rem;
  background-color: #000;
  display: block;
    
  z-index: 1;
}



.buttonAround::after {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 0;
  width: 25%;
  height: 100%;
  background: linear-gradient(
    90deg,
    
    rgba(255, 135, 105, 0.401) 0%,
    rgba(250, 178, 150, 0.616) 20%,
    rgba(159, 159, 159, 0.2) 70%,
    rgba(0, 0, 0, 0.2) 100%
  );
  z-index: 1;
}

.buttonShadow {
  position: relative;
  top: -12.5rem;
  left: 2.2rem;
  width: 20rem;
  height: 12rem;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(12,10,10,0.9) 0%, rgba(12,10,10,0.9) 100%);
  z-index: 0;
  transform-style: preserve-3d;
  transform: rotateX(70deg) skewY(4deg);
  box-shadow: 5px 10px 40px 30px rgba(12, 10, 10, 0.9);
  -webkit-transform: rotateX(70deg) skewY(4deg);
  -moz-transform: rotateX(70deg) skewY(4deg);
  -ms-transform: rotateX(70deg) skewY(4deg);
  -o-transform: rotateX(70deg) skewY(4deg);
}
