@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&family=Monoton&display=swap);
:root {
  --colors-primary: #151617;
  --colors-background: white;
  --colors-accent: rgb(220, 20, 60);
  --colors-portfolio: #222324;
  --colors-content: rgb(124, 123, 123);
  --colors-footer: #303031;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  font-size: 1.6rem;
  width: 100%;
}

body::after {
  content: url(/static/media/bg_21_small.af1c0d03.png)
    url(/static/media/bg_21_xsmall.7ba06d34.png);
  display: none;
}

h1 {
  font-size: 3.2rem;
  font-family: "Noto Serif", serif;
  font-weight: 700;
  color: white;
  color: var(--colors-background);
  padding-top: 100px;
}

@media only screen and (min-width: 576px) {
  h1 {
    font-size: 4rem;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 8rem;
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 10rem;
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 12rem;
  }
}


.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  align-items: center;
}

.fallbackLanding {
  position: fixed;
  position: fixed;
  right: 0%;
  bottom: 0%;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background:  #151617;
}


.footer {
  display: flex;
  width: 100%;
}

@media only screen and (min-width: 1200px) {

  .fallbackLanding {
    position: fixed;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: 100vh;
    min-width: 100vw;
    min-height: 100vh;
    background: url(/static/media/bg_21_xsmall.7ba06d34.png) rgba(24, 22, 23, 0.7);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-blend-mode: multiply;
  }
}


.dark {
  --colors-primary: #151617;
  --colors-background: white;
  --colors-accent: rgb(220, 20, 60);
  --colors-portfolio: #222324;
  --colors-content: rgb(124, 123, 123);
  --colors-footer: #303031;
  background-color: #151617;
  min-height: 100vh;
}

.light {
  --colors-primary: #fff;
  --colors-background: #151617;
  --colors-accent: rgb(220, 20, 60);
  --colors-portfolio: rgb(206, 206, 206);
  --colors-content: rgb(124, 123, 123);
  --colors-footer: #303031;
  background-color: #fff;
  min-height: 100vh;
}

.NavBar_navbar-container__2NDX1 {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: var(--colors-primary);
  color: var(--colors-background);
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  font-size: 0.6em;
}

.NavBar_nav-wrapper__24sCt {
  display: block;
  top: 90px;
  right: 0;
  width: 200px;
  position: absolute;
}

.NavBar_nav-wrapper-web__17RQ7 {
  display: none;
}

.NavBar_logo-name-wrapper__3aZx6 {
  display: flex;
}

h2 {
  font-family: "Courier New", Courier, monospace;
  font-weight: 300;
  color: var(--colors-background);
}

.NavBar_dot__1WaGo {
  color: var(--colors-accent);
}

.NavBar_logo__25s9x {
  width: 70px;
  height: 70px;
}

.NavBar_hamburger-icon__1OQXo {
  color: var(--colors-background);
  font-size: 2.2em;
  margin-right: 10px;
  margin-bottom: 12px;
}

#NavBar_hamburger__bK5e4 {
  display: none;
}

.NavBar_hamburger__bK5e4 {
  display: none;
}

.NavBar_menuClass__2SOWB {
  width: 200px;
  padding: 20px 10px;
  font-weight: 300;
  text-decoration: none;
  color: var(--colors-background);
  text-align: center;
  box-sizing: border-box;
  display: block;
  border-bottom: 2px solid var(--colors-content);
  background-color: var(--colors-primary);
}

@media only screen and (min-width: 992px) {
  .NavBar_nav-wrapper-web__17RQ7 {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .NavBar_nav-wrapper__24sCt {
    display: none;
  }

  .NavBar_hamburger-icon__1OQXo {
    display: none;
  }

  .NavBar_menuClass__2SOWB {
    width: 120px;
    background-color: var(--colors-primary);
    border: none;
    font-weight: 300;
  }

  .NavBar_menuClass__2SOWB:hover {
    color: var(--colors-accent);
  }
}
@media only screen and (min-width: 1600px) {
  .NavBar_navbar-container__2NDX1 {
    font-size: 1.2rem;
  }
  .NavBar_menuClass__2SOWB {
    width: 160px;
  }
}

.Landing_landing__EFtY_ {
  position: fixed;
  right: 0%;
  bottom: 0%;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  background: url(/static/media/bg_21_small.af1c0d03.png) rgba(24, 22, 23, 0.7);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-blend-mode: multiply;
}

.Landing_landingWrapper__3F99k {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Landing_neon__21Wbm {
  padding-top: 16rem;
  transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.Landing_landingButton__3eVb6 {
  margin-bottom: 7rem;
  transform: scale(1.6);
  max-height: 15rem;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -ms-transform: scale(1.6);
  -o-transform: scale(1.6);
}

@media only screen and (min-width: 1200px) {
  .Landing_neon__21Wbm {
    padding-top: 10rem;
    padding-right: 3rem;
    align-self: flex-end;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}

  .Landing_landingButton__3eVb6 {
    align-self: flex-end;
    margin-bottom: 2rem;
    padding-right: 10rem;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}
}

@media only screen and (min-width: 2000px) {
  .Landing_neon__21Wbm {
    padding-top: 18rem;
    padding-right: 12rem;
    /*margin-left: 3rem;
    */transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

  .Landing_landingButton__3eVb6 {
    margin-right: 24rem;
    margin-bottom: 6rem;
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
}
}

:root {
    --colors-neon-letter: rgb(218, 17, 51);
    --colors-neon-green: hsla(350, 100%, 59%, 0.7);
    --colors-neon-accent: rgb(247, 140, 40);
}

.Neon_neonWrapper__2dVlO {
    /* position: absolute; */
    display: flex;
    flex-direction: row;
    font-family: 'Monoton', sans-serif;
    font-size: 6rem;
    font-weight: 700;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    letter-spacing: 18px;
    font-stretch: ultra-expanded;
    /* width: 30rem;
    height: 10rem; */
    /* align-items: center;
    justify-content: center; */
    /* transform: translate(-50%, -50%); */
    color: rgb(218, 17, 51);
    color: var(--colors-neon-letter);
    text-shadow: 
    0 0 7px rgb(247, 140, 40),
    0 0 10px rgb(247, 140, 40),
    0 0 21px rgb(247, 140, 40),
    0 0 42px hsla(350, 100%, 59%, 0.7),
    0 0 82px hsla(350, 100%, 59%, 0.7),
    0 0 92px hsla(350, 100%, 59%, 0.7),
    0 0 102px hsla(350, 100%, 59%, 0.7),
    0 0 151px hsla(350, 100%, 59%, 0.7);
    text-shadow: 
    0 0 7px var(--colors-neon-accent),
    0 0 10px var(--colors-neon-accent),
    0 0 21px var(--colors-neon-accent),
    0 0 42px var(--colors-neon-green),
    0 0 82px var(--colors-neon-green),
    0 0 92px var(--colors-neon-green),
    0 0 102px var(--colors-neon-green),
    0 0 151px var(--colors-neon-green);
    
}

.Neon_o__2pHQj {
    animation: Neon_flicker__28eux 30s linear 12s infinite alternate;
    -webkit-animation: Neon_flicker__28eux 30s linear 12s infinite alternate;
}


.Neon_l__2MPM6 {
    animation: Neon_flicker__28eux 1.5s infinite alternate;
    -webkit-animation: Neon_flicker__28eux 1.5s infinite alternate;
}

.Neon_j2__3RPXd {
    animation: Neon_flicker__28eux 5s infinite alternate;
    -webkit-animation: Neon_flicker__28eux 5s infinite alternate;
}


@keyframes Neon_flicker__28eux {
    
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        text-shadow:
        0 0 7px rgb(247, 140, 40),
        0 0 10px rgb(247, 140, 40),
        0 0 21px rgb(247, 140, 40),
        0 0 42px hsla(350, 100%, 59%, 0.7),
        0 0 82px hsla(350, 100%, 59%, 0.7),
        0 0 92px hsla(350, 100%, 59%, 0.7),
        0 0 102px hsla(350, 100%, 59%, 0.7),
        0 0 151px hsla(350, 100%, 59%, 0.7);
  
        text-shadow:
        0 0 7px var(--colors-neon-accent),
        0 0 10px var(--colors-neon-accent),
        0 0 21px var(--colors-neon-accent),
        0 0 42px var(--colors-neon-green),
        0 0 82px var(--colors-neon-green),
        0 0 92px var(--colors-neon-green),
        0 0 102px var(--colors-neon-green),
        0 0 151px var(--colors-neon-green);
    
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }
  
  @media only screen and (min-width: 1200px) {
   
    .Neon_neonWrapper__2dVlO {
        font-size: 11rem;
    }
  }

  @media only screen and (min-width: 2000px) {
    
    .Neon_neonWrapper__2dVlO {
        font-size: 16rem;
    }
}
:root {
  --colors-pink-light: rgba(255, 226, 246, 1);
  --colors-pink-dark: rgba(255, 119, 193, 1);
  --colors-gradient: radial-gradient(
    circle,
    rgb(255, 239, 250) 0%,
    rgb(255, 34, 155) 100%
  );
  --colors-gradient-yellow: linear-gradient(0deg, rgba(251,242,1,1) 0%, rgba(196,0,0,1) 100%);
  --colors-gradient-black: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(12,10,10,0.9) 100%);
}


.LandingButton_buttonWrapper__20SFr {
  top: -2rem;
  position: relative;
  width: 18rem;
  height: 15rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  z-index: 1;
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}

.LandingButton_hover__1uMwW {
    position: absolute;
    top: -2.7rem;
    left: 1.46rem;
    width: 15rem;
    height: 13rem;
    border-radius: 50%;
    /*background-color: blue;
    */z-index: 6;
    text-align: center;
    display: inline-block;
    transform-style: preserve-3d;
    transform: rotateX(50deg);
    -webkit-transform: rotateX(50deg);
    -moz-transform: rotateX(50deg);
    -ms-transform: rotateX(50deg);
    -o-transform: rotateX(50deg);
}

.LandingButton_hover__1uMwW:hover {
    box-shadow: inset 0px 0px 40px 30px rgb(93, 0, 50);
}

.LandingButton_glass__3AkhH {
  position: absolute;
  top: -0.7rem;
  left: 4rem;
  width: 10rem;
  height: 6rem;
  background-color: rgba(255, 255, 255);
  opacity: 0.4;
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  z-index: 5;
  text-align: center;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}



.LandingButton_top__2VuTD {
  /*position: absolute;
  */padding-top: 0.8rem;
  top: 0;
  left: 0;
  width: 8rem;
  height: 5.5rem;
  background-color: rgb(255, 2, 2);
  border-radius: 50%;
  font-size: 1rem;
  color: #fff;
  z-index: 4;
  text-align: center;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}



.LandingButton_topBackgroundPink__jl74U {
  position: absolute;
  top: -1rem;
  left: 3rem;
  /*margin-top: -0.8rem;
  */width: 12rem;
  height: 9rem;
  background: radial-gradient(
    circle,
    rgb(255, 239, 250) 0%,
    rgb(255, 34, 155) 100%
  );
  background: var(--colors-gradient);
  border-radius: 50%;
  z-index: 3;
  text-align: center;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}


.LandingButton_topBackgroundBlack__9ue3K {
  position: absolute;
  top: -2.7rem;
  left: 1.46rem;
  /*margin-top: -3.5rem;
  */width: 15rem;
  height: 13rem;
  background-color: #000;
  border-radius: 50%;
  z-index: 2;
  text-align: center;
  transform-style: preserve-3d;
  transform: rotateX(50deg);
  -webkit-transform: rotateX(50deg);
  -moz-transform: rotateX(50deg);
  -ms-transform: rotateX(50deg);
  -o-transform: rotateX(50deg);
}


.LandingButton_text__3NbDF {
  font-size: 4rem;
  margin-top: -0.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
a {
  text-decoration: none;
}

.LandingButton_reverse__BhsMg {
  display: inline-block;
  padding: 0;
  margin-left: -60px;
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
}

.LandingButton_buttonAround__oDZtr {
  position: absolute;
  top: 0;
  left: 1.5rem;
  width: 14.94rem;
  height: 18rem;
  background-color: #000;
  display: block;
    
  z-index: 1;
}



.LandingButton_buttonAround__oDZtr::after {
  content: "";
  position: absolute;
  display: inline-block;
  left: 0;
  bottom: 0;
  width: 25%;
  height: 100%;
  background: linear-gradient(
    90deg,
    
    rgba(255, 135, 105, 0.401) 0%,
    rgba(250, 178, 150, 0.616) 20%,
    rgba(159, 159, 159, 0.2) 70%,
    rgba(0, 0, 0, 0.2) 100%
  );
  z-index: 1;
}

.LandingButton_buttonShadow__1wZdO {
  position: relative;
  top: -12.5rem;
  left: 2.2rem;
  width: 20rem;
  height: 12rem;
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(12,10,10,0.9) 0%, rgba(12,10,10,0.9) 100%);
  z-index: 0;
  transform-style: preserve-3d;
  transform: rotateX(70deg) skewY(4deg);
  box-shadow: 5px 10px 40px 30px rgba(12, 10, 10, 0.9);
  -webkit-transform: rotateX(70deg) skewY(4deg);
  -moz-transform: rotateX(70deg) skewY(4deg);
  -ms-transform: rotateX(70deg) skewY(4deg);
  -o-transform: rotateX(70deg) skewY(4deg);
}

.Footer_footer-container__11qMs {
  background-color: var(--colors-primary);
  width: 100%;
  position: fixed;
  bottom: 0;
  flex-shrink: 0;
  display: flex;
  z-index: 20;
  justify-content: space-between;
  align-items: flex-end;
}

.Footer_buttonContainer__fov70 {
  margin: 0;
}

.Footer_info-container__12B8z {
  display: flex;
  padding-right: 10px;
  grid-column-gap: 10px;
  column-gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.Footer_contact-text__3r4Qz {
  color: var(--colors-footer);
  font-size: 0.8rem;
  font-weight: 200;
}

.Footer_icons__3cR-u {
  display: flex;
  align-items: flex-end;
}
.Footer_contact-icons__2uMq3 a {
  font-size: 1.2rem;
  color: var(--colors-footer);
}

.Footer_contact-icons__2uMq3 a:hover {
  color: var(--colors-background);
}

.Footer_mail__2spT3 {
  font-size: 1.46rem;
  margin-bottom: -3px;
}

.ThemeButton_switch-wrapper__uXfUs {
    min-width: 100%;
    background-color: var(--colors-accent);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.ThemeButton_switch__1fMcv {
    display: none;
}

.ThemeButton_switch-btn__V2_az {
    box-sizing: border-box;
    position: relative;
    outline: none;
    width: 3rem;
    height: 1.4rem;
    display: block;
    cursor: pointer;
    background: var(--colors-primary);
    border-radius: 2rem;
    padding: 2px;
    transition: all .4s ease;
}


.ThemeButton_switch-btn__V2_az::after {
    position: relative;
    left: 0;
    display: block;
    content: "";
    width: 40%;
    height: 100%;
    border-radius: 50%;
    background: var(--colors-content);
    transition: all .2s ease;
}

.ThemeButton_switch__1fMcv:checked + .ThemeButton_switch-btn__V2_az {
    background: var(--colors-primary);
}

.ThemeButton_switch__1fMcv:checked + .ThemeButton_switch-btn__V2_az::after {
    left: 60%;
}


:root {
    --colors-yellow: gold;
    --colors-orange: orange;
    --colors-red: orangered;
    --colors-textRed: crimson;
}

.Message_messageContainer__3ThWF {
    /* padding-top: 120px; */
    padding-bottom: 60px;
    width: 1320px;
    max-height: 300px;
    background-color: var(--colors-primary);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.Message_title__11w7W {
    line-height: 0.3rem;
    margin-top: 200px;
}


@media only screen and (min-width: 992px) {
    .Message_messageContainer__3ThWF {
        min-height: 500px;
    }
}
.Flame_container__o_fjd {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:  100%;
  height: auto;
}

.Flame_red__1ROUb {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  width: 160px;
  height: 160px;
  background: var(--colors-red);
  box-shadow: 0px 0px 1px 0px var(--colors-red);
  /* bottom: 0; */
  transform: rotate(-51deg) scale(1.5, 1.5);
  -webkit-transform: rotate(-51deg) scale(1.5, 1.5);
  -moz-transform: rotate(-51deg) scale(1.5, 1.5);
  -ms-transform: rotate(-51deg) scale(1.5, 1.5);
  -o-transform: rotate(-51deg) scale(1.5, 1.5);
}

.Flame_red__1ROUb::after {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  width: 140px;
  height: 140px;
  background: var(--colors-red);
  opacity: 0.9;
  box-shadow: 0px 0px 20px 12px var(--colors-red);
  /* bottom: 0; */
  margin-bottom: 10px;
  transform: rotate(11deg);
  -webkit-transform: rotate(11deg);
  -moz-transform: rotate(11deg);
  -ms-transform: rotate(11deg);
  -o-transform: rotate(11deg);
  transform-origin: center bottom;
}

.Flame_orange__20XPc {
  position: absolute;
  width: 150px;
  height: 150px;
  /* bottom: -10px; */
  background: var(--colors-orange);
  box-shadow: 0px 0px 8px 3px var(--colors-orange);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-47deg) scale(1.5, 1.5);
  -webkit-transform: rotate(-47deg) scale(1.5, 1.5);
  -moz-transform: rotate(-47deg) scale(1.5, 1.5);
  -ms-transform: rotate(-47deg) scale(1.5, 1.5);
  -o-transform: rotate(-47deg) scale(1.5, 1.5);
  animation-name: Flame_flicker2__3W0U3;
  animation-duration: 1s;
  animation-delay: 300ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.Flame_yellow__1G8sI {
  position: absolute;
  width: 80px;
  height: 80px;
  /* bottom: 10px; */
  background: var(--colors-yellow);
  box-shadow: 0px 0px 6px 4px var(--colors-yellow);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  animation-name: Flame_flicker2__3W0U3;
  animation-duration: 2s;
  animation-delay: 200ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes Flame_flicker1__3xyuu {
  0% {
    transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
  }

  40% {
    transform: rotate(6deg) scaleY(1.04);
    -webkit-transform: rotate(6deg) scaleY(1.04);
    -moz-transform: rotate(6deg) scaleY(1.04);
    -ms-transform: rotate(6deg) scaleY(1.04);
    -o-transform: rotate(6deg) scaleY(1.04);
  }
  70% {
    transform: rotate(-4deg) scaleY(0.92);
    -webkit-transform: rotate(-4deg) scaleY(0.92);
    -moz-transform: rotate(-4deg) scaleY(0.92);
    -ms-transform: rotate(-4deg) scaleY(0.92);
    -o-transform: rotate(-4deg) scaleY(0.92);
  }
  100% {
    transform: rotate(1deg);
  }
}

@keyframes Flame_flicker2__3W0U3 {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-47deg) scaleY(1.04);
    -webkit-transform: rotate(-47deg) scaleY(1.04);
    -moz-transform: rotate(-47deg) scaleY(1.04);
    -ms-transform: rotate(-47deg) scaleY(1.04);
    -o-transform: rotate(-47deg) scaleY(1.04);
  }
  100% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
}

.Flame_sparkles__3DadI {
  position: absolute;
  display: flex;
  align-items: center;
  top: -260px;
  margin-left: 26px;
  grid-column-gap: 4px;
  column-gap: 4px;
  height: 120px;
  width: 40px;
}

.Flame_flame__6Cujc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Flame_spark1__2e6D2 {
  width: 1px;
  height: 20px;
  margin-bottom: 10px;
  background-color: var(--colors-yellow);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  animation: Flame_sparkle__3VPDf 0.8s ease-in-out infinite;
  animation-delay: 0.3s;
  -webkit-animation: Flame_sparkle__3VPDf 0.8s ease-in-out infinite;
  box-shadow: 0px 0px 6px 5px var(--colors-yellow);
}
.Flame_spark2__1cZil {
  width: 1px;
  height: 24px;
  margin-bottom: 4px;
  background-color: var(--colors-orange);
  box-shadow: 0px 0px 6px 4px var(--colors-orange);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  animation: Flame_sparkle__3VPDf 1.2s ease-in-out infinite;
  animation-delay: 1s;
  -webkit-animation: Flame_sparkle__3VPDf 1.2s ease-in-out infinite;
}

.Flame_spark3__1gfer {
  width: 1px;
  height: 20px;
  background-color: var(--colors-red);
  box-shadow: 0px 0px 10px 6px var(--colors-red);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  animation: Flame_sparkle__3VPDf 1s ease-in-out infinite;
  -webkit-animation: Flame_sparkle__3VPDf 1s ease-in-out infinite;
}

@keyframes Flame_sparkle__3VPDf {
  0% {
    top: 30px;
    height: 0;
  }
  33% {
    top: 20px;
    height: 20px;
  }
  66% {
    top: 10px;
    height: 10px;
  }
  100% {
    top: 0;
    height: 0;
  }
}

.Title_text__V_G70 {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     text-align: justify;
    padding: 0 10px;
}

.Title_title__1Cg_h {
    color: var(--colors-yellow);
    font-size: 2.9rem;
   
    display: inline-block;
    text-align: center;
    width: 100%;
}

.Title_lighter__38MIb{
 
  color: var(--colors-red);
  margin-top: 50px;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  font-weight: 900;
  font-size: 7.6rem;
  text-align: center;
  display: inline-block;
    width: 100%;
}
