.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:  100%;
  height: auto;
}

.red {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  width: 160px;
  height: 160px;
  background: var(--colors-red);
  box-shadow: 0px 0px 1px 0px var(--colors-red);
  /* bottom: 0; */
  transform: rotate(-51deg) scale(1.5, 1.5);
  -webkit-transform: rotate(-51deg) scale(1.5, 1.5);
  -moz-transform: rotate(-51deg) scale(1.5, 1.5);
  -ms-transform: rotate(-51deg) scale(1.5, 1.5);
  -o-transform: rotate(-51deg) scale(1.5, 1.5);
}

.red::after {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  width: 140px;
  height: 140px;
  background: var(--colors-red);
  opacity: 0.9;
  box-shadow: 0px 0px 20px 12px var(--colors-red);
  /* bottom: 0; */
  margin-bottom: 10px;
  transform: rotate(11deg);
  -webkit-transform: rotate(11deg);
  -moz-transform: rotate(11deg);
  -ms-transform: rotate(11deg);
  -o-transform: rotate(11deg);
  transform-origin: center bottom;
}

.orange {
  position: absolute;
  width: 150px;
  height: 150px;
  /* bottom: -10px; */
  background: var(--colors-orange);
  box-shadow: 0px 0px 8px 3px var(--colors-orange);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-47deg) scale(1.5, 1.5);
  -webkit-transform: rotate(-47deg) scale(1.5, 1.5);
  -moz-transform: rotate(-47deg) scale(1.5, 1.5);
  -ms-transform: rotate(-47deg) scale(1.5, 1.5);
  -o-transform: rotate(-47deg) scale(1.5, 1.5);
  animation-name: flicker2;
  animation-duration: 1s;
  animation-delay: 300ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.yellow {
  position: absolute;
  width: 80px;
  height: 80px;
  /* bottom: 10px; */
  background: var(--colors-yellow);
  box-shadow: 0px 0px 6px 4px var(--colors-yellow);
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  animation-name: flicker2;
  animation-duration: 2s;
  animation-delay: 200ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes flicker1 {
  0% {
    transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
  }

  40% {
    transform: rotate(6deg) scaleY(1.04);
    -webkit-transform: rotate(6deg) scaleY(1.04);
    -moz-transform: rotate(6deg) scaleY(1.04);
    -ms-transform: rotate(6deg) scaleY(1.04);
    -o-transform: rotate(6deg) scaleY(1.04);
  }
  70% {
    transform: rotate(-4deg) scaleY(0.92);
    -webkit-transform: rotate(-4deg) scaleY(0.92);
    -moz-transform: rotate(-4deg) scaleY(0.92);
    -ms-transform: rotate(-4deg) scaleY(0.92);
    -o-transform: rotate(-4deg) scaleY(0.92);
  }
  100% {
    transform: rotate(1deg);
  }
}

@keyframes flicker2 {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-47deg) scaleY(1.04);
    -webkit-transform: rotate(-47deg) scaleY(1.04);
    -moz-transform: rotate(-47deg) scaleY(1.04);
    -ms-transform: rotate(-47deg) scaleY(1.04);
    -o-transform: rotate(-47deg) scaleY(1.04);
  }
  100% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }
}

.sparkles {
  position: absolute;
  display: flex;
  align-items: center;
  top: -260px;
  margin-left: 26px;
  column-gap: 4px;
  height: 120px;
  width: 40px;
}

.flame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spark1 {
  width: 1px;
  height: 20px;
  margin-bottom: 10px;
  background-color: var(--colors-yellow);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  animation: sparkle 0.8s ease-in-out infinite;
  animation-delay: 0.3s;
  -webkit-animation: sparkle 0.8s ease-in-out infinite;
  box-shadow: 0px 0px 6px 5px var(--colors-yellow);
}
.spark2 {
  width: 1px;
  height: 24px;
  margin-bottom: 4px;
  background-color: var(--colors-orange);
  box-shadow: 0px 0px 6px 4px var(--colors-orange);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  animation: sparkle 1.2s ease-in-out infinite;
  animation-delay: 1s;
  -webkit-animation: sparkle 1.2s ease-in-out infinite;
}

.spark3 {
  width: 1px;
  height: 20px;
  background-color: var(--colors-red);
  box-shadow: 0px 0px 10px 6px var(--colors-red);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  animation: sparkle 1s ease-in-out infinite;
  -webkit-animation: sparkle 1s ease-in-out infinite;
}

@keyframes sparkle {
  0% {
    top: 30px;
    height: 0;
  }
  33% {
    top: 20px;
    height: 20px;
  }
  66% {
    top: 10px;
    height: 10px;
  }
  100% {
    top: 0;
    height: 0;
  }
}
