.text {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     text-align: justify;
    padding: 0 10px;
}

.title {
    color: var(--colors-yellow);
    font-size: 2.9rem;
   
    display: inline-block;
    text-align: center;
    width: 100%;
}

.lighter{
 
  color: var(--colors-red);
  margin-top: 50px;
  font-variant: small-caps;
  font-weight: 900;
  font-size: 7.6rem;
  text-align: center;
  display: inline-block;
    width: 100%;
}